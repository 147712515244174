/* eslint-disable max-lines */
/**
 * index
 *
 * @author fukui
 */
import { getBrowserInfos } from '@falla/utils/src/common-util';
import { randomString } from '@falla/utils/src/crypto-util';
import { parse } from '@falla/utils/src/number-util';
import dsBridge from 'dsbridge';

import {
  ApiEnvRes,
  AppApiRes,
  AppTag,
  ChooseImageData,
  ChooseImageParams,
  ChooseUserParams,
  ChooseUserResult,
  CpGift,
  CpLevelDialogParams,
  CpStatus,
  DSBridge,
  GiftDialogIndex,
  GotoRoomWithGame,
  GotoWalletParams,
  InnerNoticeType,
  PlayAnimationUrlParams,
  PopupWebviewExtData,
  SetShareParams,
  ShareWithFamilyInfoParams,
  SimpleRoom,
  StorageMapping,
  TextButtonIconTypes,
  UploadFileParams,
  UploadFileResult,
  UserDeviceInfo,
  UserInfo,
  WALLET_PAGES,
} from './types';

type CallbackFunction = (data?: any) => any;

const isAndroid = () => {
  const UA = window.navigator.userAgent.toLowerCase();
  return UA.indexOf('android') > 0;
};

class AppBridge {
  private dsBridge: DSBridge;
  private webviewInit = false;
  private hasNativeMethodCache: {
    [key in string]: boolean;
  } = {};
  appTag = AppTag.Yigo;

  constructor() {
    this.dsBridge = dsBridge;
    dsBridge.disableJavascriptDialogBlock(true);
    const { appTag } = this.getUserDeviceInfo();
    this.appTag = appTag || this.appTag;
  }

  /**
   * 跳转到钱包页
   * @param page
   */
  gotoWallet(page: GotoWalletParams = { index: WALLET_PAGES.coin, moduleName: 'H5' }) {
    let params = page;
    if (page === WALLET_PAGES.coin || page === WALLET_PAGES.gem) {
      console.warn('[AppBridge] gotoWallet传参发生了调整，请按对象的方式传参');
      params = {
        moduleName: 'H5',
        index: page,
      };
    }
    const { index, moduleName } = params as { moduleName: string; index: WALLET_PAGES };
    this.gotoWithScheme(
      `${this.isYigoApp() ? 'yigo' : 'vochat'}://wallet/recharge?index=${index}&moduleName=${encodeURIComponent(
        moduleName,
      )}`,
    );
  }

  /**
   * 跳转到钱包页
   * @param source 来源，用于统计充值的渠道，可用中文
   * @param index 0-金币充值；1-钻石兑换
   */
  gotoWalletV2({ moduleName, index = WALLET_PAGES.coin }: { moduleName: string; index: WALLET_PAGES }) {
    this.gotoWithScheme(
      `${this.isYigoApp() ? 'yigo' : 'vochat'}://wallet/recharge?index=${index}&moduleName=${encodeURIComponent(
        moduleName,
      )}`,
    );
  }

  /**
   * 获取app版本
   */
  getAppVersion(): string {
    return this.call('getAppVersion');
  }

  /**
   * 获取设备信息
   */
  getUserDeviceInfo() {
    let result: Partial<UserDeviceInfo> = {};
    try {
      const temp: string = dsBridge.call('getUserDeviceInfo', '');
      if (temp) result = (temp && JSON.parse(temp)) as UserDeviceInfo;
    } catch (err) {
      console.log(err);
    }
    return result;
  }

  /**
   * 获取app的用户完整信息
   */
  getAppUserInfo(): Partial<UserInfo> {
    let result = {};
    try {
      const temp: string = dsBridge.call('getAppUserInfo', '');
      result = ((temp && JSON.parse(temp)) as UserInfo) || {};
    } catch (err) {
      console.log(err);
    }
    return result;
  }

  /**
   * 获取app uid信息
   */
  getAppUid(): number {
    return Number(this.call('getAppUid')) || 0;
  }

  /**
   * 获取app token信息
   */
  getAppToken(): string {
    return this.call('getAppToken');
  }

  /**
   * 获取请求的自定义 header 头
   */
  getRequestHeaders(): any {
    let result = {};
    try {
      const [uid = '', token = '', userDeviceInfo = {}] = [
        this.getAppUid(),
        this.getAppToken(),
        this.getUserDeviceInfo(),
      ];

      const { isAndroid } = getBrowserInfos();

      const {
        mac,
        version,
        versionCode,
        deviceModel,
        language,
        systemVersion,
        os,
        realVersion,
        appTag,
        systemCountryCode,
      } = userDeviceInfo || {};
      result = {
        'X-Uid': uid,
        'X-Authorization': token,
        'X-Mac': mac,
        'X-Language': language,
        'X-Os': os,
        'X-Version': isAndroid ? versionCode : version,
        'X-Model': deviceModel,
        'X-SystemVersion': systemVersion,
        'X-RealVersion': realVersion,
        'X-AppTag': appTag || AppTag.Falla,
        'X-SystemCountryCode': systemCountryCode,
      };
    } catch (err) {
      console.log('getLoginInfo-err', err);
    }
    return result;
  }

  getAppTag() {
    return appBridge.getUserDeviceInfo()?.appTag || this.appTag;
  }

  isFoloApp() {
    if (this.appTag === AppTag.Folo) return true;
    if (window?._appBridge_?.getAppTag() === AppTag.Folo) return true;
    return location.href.includes('fololive.com');
  }

  isFallaLite() {
    return this.appTag === AppTag.FallaLite;
  }

  isJoyMiApp() {
    if (this.appTag === AppTag.JoyMi) return true;
    if (window?._appBridge_?.getAppTag() === AppTag.JoyMi) return true;
    const url = location.href;
    return url.includes('joymi.live') || location.href.includes('joymiweb');
  }

  isYigoApp() {
    if (this.appTag === AppTag.Yigo) return true;
    if (window?._appBridge_?.getAppTag() === AppTag.Yigo) return true;
    const url = location.href;
    return url.includes('yigolive') || location.href.includes('yigoweb');
  }

  isArhboApp() {
    if (this.appTag === AppTag.Arhbo) return true;
    if (window?._appBridge_?.getAppTag() === AppTag.Arhbo) return true;
    const url = location.href;
    return url.includes('arhbo.chat');
  }

  isYiChatApp() {
    if (this.appTag === AppTag.YiChat) return true;
    if (window?._appBridge_?.getAppTag() === AppTag.YiChat) return true;
    const url = location.href;
    return url.includes('yichat.live');
  }

  /**
   * 跳转到用户的个人主页
   * @param uid
   */
  gotoUser(uid: number): void {
    uid && dsBridge.call('gotoUser', uid);
  }

  /**
   * 跳转到用户的房间
   * @param gid
   */
  gotoRoom(gid: number): void {
    gid && this.call('gotoGroup', gid);
  }

  /**
   * 跳转到游戏房间
   * @param gid
   * @param gameId
   * @param type
   */
  gotoRoomWithGame({ gid, gameId = 1, type = 1 }: GotoRoomWithGame): void {
    this.call('gotoGroupWithGame', {
      gid,
      gameId,
      type,
    });
  }

  /**
   * 关闭 webview
   */
  async closeWebView() {
    this._closeWebView(true);
  }

  async _closeWebView(checkIOS = true) {
    if (checkIOS && getBrowserInfos().isIOS) {
      // @ts-ignore
      const onWebviewClose = window._dsf && window._dsf['onWebviewClose'];
      onWebviewClose && (await onWebviewClose());
    }
    try {
      await this.callPromise('closeWebView');
    } catch (e) {}
  }

  /**
   * 设置webview的返回按钮
   * @param close
   * true:  返回行为直接退出webview
   * false: 默认方式：返回行为同浏览器的history(-1)
   */
  async setWebViewBackBtn(close: boolean) {
    try {
      await this.callPromise('setWebViewBackBtn', { close });
    } catch (e) {}
  }

  /**
   * 设置分享的内容和样式
   * @param params
   */
  setShare(params: SetShareParams): void {
    this.call('setShare', params);
  }

  /**
   * 触发分享
   */
  openShare(): void {
    this.call('openShare');
  }

  /**
   * 分享网页到whatsapp
   * @param text
   */
  async shareWhatsapp(opt: { text: string }) {
    try {
      await this.callPromise('shareWhatsapp', opt);
    } catch (e) {}
  }

  async shareFacebook(opt: { link: string; content: string }) {
    try {
      await this.callPromise('shareFacebook', opt);
    } catch (e) {}
  }

  /**
   * 复制
   * @param content
   */
  async copy(content: string): Promise<boolean> {
    try {
      const hasCopy = this.hasNativeMethod('shareLink');
      if (!hasCopy) {
        const input = document.createElement('input');
        input.value = content;
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, input.value.length);
        document.execCommand('Copy');
        document.body.removeChild(input);
        return true;
      }
      const params = {
        url: content,
      };
      await this.callPromise('shareLink', params);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * 选择图片
   * @param count
   * @param sizeType
   * @param mp4Enabled
   * @param duration
   */
  chooseImage({
    count = 1,
    sizeType = 'compressed',
    mp4Enabled = false,
    duration,
  }: ChooseImageParams): Promise<ChooseImageData> {
    return this.callAsync('chooseImage', {
      count,
      sizeType,
      mp4Enabled: !!mp4Enabled,
      duration,
    });
  }

  downloadImage(params: { url: string }): Promise<any> {
    return this.callAsync('downloadImage', params);
  }

  /**
   * 上传图片
   * @param filePath
   * @param fileType
   */
  uploadFile({ filePath = '', fileType = 'image' }: UploadFileParams) {
    return this.uploadMultiFile([
      {
        filePath,
        fileType,
      },
    ]);
  }

  /**
   * 上传多张文件
   * @param params
   */
  uploadMultiFile(params: UploadFileParams[]): Promise<UploadFileResult> {
    return this.callAsync('uploadFile', params);
  }

  /**
   * 是否有原生方法
   * @param handlerName
   * @param type
   */
  hasNativeMethod(handlerName: string, type?: 'all' | 'asyn' | 'syn'): boolean {
    // eslint-disable-next-line no-prototype-builtins
    const flag = this.hasNativeMethodCache.hasOwnProperty(handlerName);
    if (flag) return this.hasNativeMethodCache[handlerName];

    // 防止调取多次与客户端通信
    const res = !!this.dsBridge.hasNativeMethod(handlerName, type);
    this.hasNativeMethodCache[handlerName] = res;
    return res;
  }

  register(handlerName: string, callback: CallbackFunction) {
    this.dsBridge.register(handlerName, callback);
  }

  call(handlerName: string, params?: any): any {
    if (!this.hasNativeMethod(handlerName)) return;
    return this.dsBridge.call(handlerName, JSON.stringify(params));
  }

  callPromise(handlerName: string, params?: any) {
    return new Promise((resolve, reject) => {
      try {
        dsBridge.call(handlerName, JSON.stringify(params), (res) => {
          resolve(res);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  callAsync(handlerName: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        dsBridge.call(handlerName, JSON.stringify(params), (res) => {
          try {
            res = JSON.parse(res) as AppApiRes<any>;
          } catch (error) {
            // ios 出错res="" 会导致 parse 出错 为了兼容 ios
            res = {
              data: null,
              status: -1,
            };
            console.log('json error', error);
          }
          if (res.status !== 0) return reject(new Error('call error'));
          resolve(res.data);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * 网页加载出错（房间半屏）
   */
  webLoadError() {
    this.call('webLoadError');
  }

  /**
   * 网页加载完成（房间半屏）
   */
  webLoadFinish() {
    this.call('webLoadFinish');
  }

  /**
   * 更新web加载进度（房间半屏）
   * @param progress
   */
  updateWebLoadProgress(progress: number) {
    this.call('updateWebLoadProgress', {
      progress,
    });
    if (progress >= 100) {
      this.webLoadFinish();
    }
  }

  /**
   * 重新加载网页（房间半屏）
   */
  reload() {
    this.call('reload');
  }

  /**
   * 关闭房间半屏
   */
  closePopupWebView() {
    this.call('closePopupWebView');
  }

  /**
   * 监听充值成功回调
   * @param callback
   */
  onRechargeSuccess(callback: CallbackFunction) {
    this.dsBridge.register('onRechargeSuccess', callback);
  }

  /**
   * 监听webview显示
   * 半屏 webview 之盒子活动用的。 一般 webview 最好别改动
   * @param callback
   */
  onWebViewShow(callback: CallbackFunction) {
    this.dsBridge.register('onWebViewShow', (data: any) => {
      if (!this.webviewInit && isAndroid()) {
        this.webviewInit = true;
        return;
      }
      callback && callback(data);
    });
  }

  /**
   * 监听webview显示 给全屏 webview 使用  双端都已支持
   * @param callback
   */
  onWebViewVisible(callback: CallbackFunction) {
    this.dsBridge.register('onWebViewShow', (data: any) => {
      callback && callback(data);
    });
  }

  /**
   * 监听webview隐藏
   * @param callback
   */
  onWebViewHide(callback: CallbackFunction) {
    this.dsBridge.register('onWebViewHide', callback);
  }

  /**
   * 监听webview返回
   * iOS 有效
   */
  onWebViewBack(callback: CallbackFunction) {
    this.dsBridge.register('onWebViewBack', callback);
  }

  /**
   * 设置app标题栏右上角的按钮
   * @param content
   */
  setWebViewHeaderTextButton(content: string | TextButtonIconTypes) {
    this.call('setWebViewHeaderTextButton', { content });
  }

  /**
   * 监听标题栏右上角按钮被点击
   * @param callback
   */
  onWebViewHeaderTextButtonClick(callback: CallbackFunction) {
    this.dsBridge.register('onWebViewHeaderTextButtonClick', callback);
  }

  /**
   * cp 状态变更
   * @param callback
   */
  onCpStatusChange(callback: (status: CpStatus) => void) {
    this.dsBridge.register('onCpStatusChange', callback);
  }

  /**
   * 赠送 cp礼物
   * @param params
   */
  sendCpGift(params: CpGift) {
    this.call('sendCpGift', params);
  }

  /**
   * 监听 app touch 事件
   * @param callback
   */
  onAppTouch(callback: CallbackFunction) {
    this.register('onAppTouch', callback);
  }

  /**
   * 显示 cp等级弹窗
   * @param params
   */
  showCpLevelDialog(params: CpLevelDialogParams) {
    this.call('showCpLevelDialog', params);
  }

  /**
   * 调用协议
   * @param scheme
   */
  gotoWithScheme(scheme: string) {
    this.call('gotoWithScheme', { scheme });
  }

  /**
   * 显示房间礼物弹窗
   * @param toIndex
   */
  showGiftDialog(toIndex: GiftDialogIndex) {
    this.call('showGiftDialog', { toIndex });
  }

  /**
   * 监听虚拟键盘展示了
   * @param callback
   */
  onVirtualKeyboardShow(callback: CallbackFunction) {
    this.register('onVirtualKeyboardShow', callback);
  }

  /**
   * 监听虚拟键盘隐藏了
   * @param callback
   */
  onVirtualKeyboardHide(callback: CallbackFunction) {
    this.register('onVirtualKeyboardHide', callback);
  }

  /**
   * 隐藏虚拟键盘
   */
  hideVirtualKeyboard() {
    this.call('hideVirtualKeyboard');
  }

  /**
   * 获取房间半屏扩展数据
   */
  getPopupWebViewExtData<T>(): PopupWebviewExtData<T> | undefined {
    try {
      const jsonString = this.call('getPopupWebViewExtData');
      return JSON.parse(jsonString);
    } catch (e) {
      return undefined;
    }
  }

  /**
   * 监听房间半屏扩展数据改变了
   * @param callback
   */
  onPopupWebViewExtDataChange<T>(callback: (extData: PopupWebviewExtData<T>) => any) {
    this.register('onPopupWebViewExtDataChange', (res) => {
      try {
        const data = JSON.parse(res) as PopupWebviewExtData<T>;
        callback(data);
      } catch (e) {}
    });
  }

  /**
   * 选择 房间用户、好友（异步调用）
   * @param params
   */
  chooseUser(params: ChooseUserParams): Promise<ChooseUserResult> {
    if (params.mode === 'radio') {
      params.checkMaxLimit = 1;
      params.checkMinLimit = 1;
    } else {
      params.checkMinLimit = params.checkMinLimit || 1;
      params.checkMaxLimit = params.checkMaxLimit || 1;
    }
    return this.callAsync('chooseUser', params);
  }

  /**
   * 获取用户当前所在房间信息
   */
  getCurrentRoomInfo(): Partial<SimpleRoom> {
    let result = {};
    try {
      const temp: string = this.call('getCurrentRoomInfo');
      result = ((temp && JSON.parse(temp)) as SimpleRoom) || {};
    } catch (err) {
      console.log(err);
    }
    return result;
  }

  /**
   * 获取缓存(如果没有找到或者缓存失效返回的结果为空字符串)
   * @param key
   */
  async dbGet<T>({ key }: { key: string }): Promise<T | undefined> {
    try {
      const data = (await this.callAsync('dbGet', { key })) as string;
      return JSON.parse(data);
    } catch (e) {
      return undefined;
    }
  }

  /**
   * 设置缓存
   * @param params
   */
  dbSet(params: StorageMapping) {
    this.callAsync('dbSet', { ...params, expire: params.expireTime });
  }

  /**
   * 删除缓存
   * @param key
   */
  dbRemove({ key }: { key: string }) {
    this.callAsync('dbRemove', { key });
  }

  /**
   * 设置 webview 侧滑返回
   * 仅 iOS 有效
   * 为 false 全屏幕手势回退均有效，  为true仅在屏幕边缘侧滑返回生效
   * 为了解决 webview 有轮播图场景
   * @param enable
   */
  async setWebViewSlideBack(enable = false) {
    try {
      await this.callPromise('setWebViewSlideBack', { enable });
    } catch (e) {}
  }

  /**
   * 在客户端打开新的 webview 窗口
   * @param opt
   * @returns
   */
  async openWebViewWindow(opt: { url: string }) {
    if (!opt.url) return;
    if (!this.isAppEnv()) {
      location.href = opt.url;
      return;
    }
    const json = {
      scheme: opt.url,
    };
    dsBridge.call('gotoWithScheme', JSON.stringify(json));
  }

  /**
   * 邀请好友加入家族
   * @param opt
   * @returns
   */
  async shareWithFamilyInfo(params: ShareWithFamilyInfoParams) {
    dsBridge.call('shareWithFamilyInfo', params);
  }

  /**
   * 获取客户端环境
   */
  getApiEnv(): Partial<ApiEnvRes> {
    let result = {};
    try {
      const temp: string = this.call('getApiEnv');
      result = ((temp && JSON.parse(temp)) as ApiEnvRes) || {};
    } catch (err) {
      console.log('getApiEnv error', err);
    }
    return result;
  }

  /**
   * 监听APP切换到后台：回到系统桌面或切换到其他APP
   * @param callback
   */
  onAppPause(callback: CallbackFunction) {
    dsBridge.register('onAppPause', callback);
  }

  /**
   * 监听APP切回到前台：从系统桌面切回到当前APP的页面
   * @param callback
   */
  onAppResume(callback: CallbackFunction) {
    dsBridge.register('onAppResume', callback);
  }

  /**
   * 设置 APP 侧滑返回
   * note: Android场景下会禁用：全面屏侧滑返回、虚拟键盘返回、左上角箭头返回；事件会代理到 onWebviewClose
   * note: iOS 场景下会禁用：全面屏侧滑返回、虚拟键盘返回、左上角箭头返回；事件会代理到 onWebviewClose
   * @param enable false-关闭侧滑返回；true：所有侧滑都生效
   */
  async _setAppSlideBackStatus(enable: boolean) {
    try {
      await this.callPromise('setAppSlideBackStatus', { enable });
    } catch (e) {}
  }

  /**
   * 监听网页关闭：客户端关闭网页前，先回调web注册的监听事件；执行完成后，在销毁当前页面
   * @param callback
   */
  onWebviewClose(callback: CallbackFunction) {
    this._setAppSlideBackStatus(false);
    dsBridge.register('onWebviewClose', async (res?: any) => {
      await callback(res);
      if (!getBrowserInfos().isIOS) this._closeWebView(false);
    });
  }

  /**
   * 将字符串转换成加密密码
   */
  getCryptoPassword(password: string): string {
    try {
      const result: string = this.call('getCryptoPassword', {
        password,
      });
      if (result) return result;
    } catch (err) {
      console.log(err);
    }
    return password;
  }

  /**
   * 跳转1v1 Ludo 游戏匹配，type类型为1
   */
  showGameLudoMatchType1Dialog() {
    this.call('showGameLudoMatchType1Dialog', {});
  }

  /**
   * 跳转1v1 Ludo 游戏匹配，type类型为1
   */
  showGameLudoMatchType2Dialog() {
    this.call('showGameLudoMatchType2Dialog', {});
  }

  isAppEnv() {
    // @ts-ignore
    return !!(window._dsbridge || window._dswk || navigator.userAgent.indexOf('_dsbridge') !== -1);
  }

  playAnimationUrl(params: PlayAnimationUrlParams) {
    this.call('playAnimationUrl', params);
  }

  /**
   * 监听金币同步
   * @param {Function} callback
   */
  onCoinSync(callback: CallbackFunction) {
    dsBridge.register('onCoinSync', callback);
  }

  notifyCoinFreeze({ coinChangeStatus, transactionId }: { coinChangeStatus: boolean; transactionId?: string }) {
    this.call('notifyCoinFreeze', { coinChangeStatus, transactionId });
  }

  generateTransactionId() {
    const uid = this.getAppUid() || 0;
    const info = this.getUserDeviceInfo();
    const deviceId = info.mac || randomString(16);
    const randomNum = parse(Math.random() * 100, 4);
    return [uid, deviceId, randomNum, Date.now()].join('_');
  }

  /**
   * 跳转到机构通知、 消息通知
   * @param id  SYSTEM_MSG 表示系统通知 --  SYSTEM_GUILD_MSG 表示机构通知
   */
  gotoInnerNotice({ id = InnerNoticeType.SYSTEM_GUILD_MSG }: { id: InnerNoticeType }): void {
    this.call('gotoInnerNotice', {
      id,
    });
  }
}

const appBridge = new AppBridge();
export default appBridge;
