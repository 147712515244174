export const LANGTYPE_MAPPING = {
  en: 'english',
  ar: 'arabic',
};

export const APP_LINK = {
  appStore: 'https://itunes.apple.com/app/id1560121894',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.iyinguo.silkroad',
  googleAPK: 'https://www.boli.live/apk/boli-official.apk',
  huaweiAPK: 'https://www.boli.live/apk/boli-huawei.apk',
};
