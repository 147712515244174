import appBridge from '@falla/app-bridge/src';
import config, { iConfig } from '@falla/config/src/config';
import {
  ActivitiesReward,
  APIGetGiftParams,
  FetchRewardConfigV3Res,
  GetActConfigRes,
  GetJackpotPrizeInfoParams,
  GetJackpotPrizeInfoRes,
  GetLotteryCarouselInfoRes,
  GetPrizePackageParams,
  GetPrizePackageRes,
  GetRankingParams,
  GetRankingRes,
  GetRoomRankRes,
  GetTaskInfoV2Res,
  GetTaskPrizeRecordsParams,
  GetTaskPrizeRecordsRes,
  GetUserRankRes,
  GetVirtualPropsParams,
  GetVirtualPropsRes,
  IUserInfoRes,
  OnLotteryParams,
  OnLotteryRes,
  OnTaskInfoReceiveV2Params,
  PrizeInfoRes,
  RankBaseParams,
  RankingApiParams,
  RoomRankingRes,
  SimpleUserApiParams,
  SimpleUserRes,
  UserRankingRes,
} from '@falla/types/src/types';
import { getBrowserInfos, getWebHostProdRelativePath } from '@falla/utils/src/common-util';
import { combineURLs } from '@falla/utils/src/string-util';

import request from './requestService';
import { FetchRewardConfigParams, ServerTimeData } from './types';

export const commonApiService = {
  /**
   * 获取服务端的时间戳
   */
  fetchServerTime() {
    const url = 'api/web/ServerTimeAr';
    return request.get<ServerTimeData>(url);
  },
  /**
   * 获取用户的金币
   */
  fetchUserCoin(): Promise<{ coin: string; diamond: number }> {
    const url = '/api/web/user/coin';
    return request.post(url);
  },

  /**
   * 房间排行榜
   * http://apisz.thevochat.com:13000/project/11/interface/api/6116
   */
  fetchRoomRanking(params: RankingApiParams) {
    const url = '/api/web/activities/rank/group';
    return request.post<RoomRankingRes>(url, params);
  },
  /**
   * 用户排行榜
   * http://apisz.thevochat.com:13000/project/11/interface/api/6115
   */
  fetchUserRanking(params: RankingApiParams) {
    const url = '/api/web/activities/rank/user';
    return request.post<UserRankingRes>(url, params);
  },

  /**
   * 通过 uid or gid 获取简单用户信息
   * @param params
   */
  fetchSimpleUserInfo(params: SimpleUserApiParams) {
    const url = '/api/web/user/simple/info';
    return request.post<SimpleUserRes>(url, params);
  },

  /**
   * @name 用户基本信息
   * @description http://apisz.thevochat.com:13000/project/11/interface/api/6161
   */
  getUserInfo() {
    const url = '/api/web/user/info';
    return request.post<IUserInfoRes>(url);
  },

  /**
   * 获取用户排行榜  （包含国家日 世界榜
   * http://apisz.thevochat.com:13000/project/11/interface/api/8498
   */
  getUserRank(params: RankBaseParams) {
    const url = '/api/web/activities_config/user';
    return request.post<GetUserRankRes>(url, params);
  },

  /**
   * 获取房间排行 （包含国家日 世界榜
   * http://apisz.thevochat.com:13000/project/11/interface/api/8498
   */
  getRoomRank(params: RankBaseParams) {
    const url = '/api/web/activities_config/group';
    return request.post<GetRoomRankRes>(url, params);
  },

  /**
   * 获取活动配置信息
   * http://apisz.thevochat.com:13000/project/11/interface/api/8540
   */
  getActConfig(params: RankBaseParams) {
    const url = '/api/web/activities_config/act';
    return request.post<GetActConfigRes>(url, params).then((res) => {
      // 部分数据格式化。
      // 将 "2,7" 格式 转化成 [2,7]
      res.subActivitiesTypes = (res.subActivitiesTypes as unknown as string)?.split?.(',')?.map((v) => Number(v)) || [];
      return res;
    });
  },

  async fetchRewardConfig(params: FetchRewardConfigParams) {
    const { region, activityId, isPartition, customPath } = params;

    const regionPath = isPartition ? region : 'ALL';
    const path = customPath || regionPath;

    const url = combineURLs(
      `${getWebHostProdRelativePath(iConfig.isDev)}/activity_gift_config_v2/`,
      [config.isHostProd ? '' : 'test/', `${config.isHostProd ? '' : 'pre'}/h5_${activityId}_${path}.json`].join(''),
    );

    const res = await this.getJson<ActivitiesReward[]>(url);
    return res.data;
  },

  async fetchRewardConfigV3(params: FetchRewardConfigParams) {
    const { region, activityId, isPartition, customPath } = params;

    const regionPath = isPartition ? region : 'ALL';
    const path = customPath || regionPath;

    const url = combineURLs(
      `${getWebHostProdRelativePath(iConfig.isDev)}/activity_gift_config_v3/`,
      `${config.isHostProd ? '' : 'pre'}/h5_${activityId}_${path}.json`,
    );

    const res = await this.getJson<FetchRewardConfigV3Res>(url);

    return res.data;
  },

  /**
   * @description  任务系统 展示当前用户在活动中的积分信息 v1 版本
   * @url http://apisz.thevochat.com:13000/project/11/interface/api/6350
   * @deprecated v1版本 弃用 请使用 getTaskInfoV2
   */
  getTaskInfo(params: RankBaseParams) {
    const url = '/api/web/activities/rank/prize/info';
    return request.post<PrizeInfoRes>(url, params);
  },

  /**
   * @description 任务系统 领取礼物
   * @url http://apisz.thevochat.com:13000/project/11/interface/api/6362
   */
  getGift(params: APIGetGiftParams) {
    const url = '/api/web/activities/rank/prize/receive';
    return request.post(url, {
      ...params,
    });
  },
  /**
   * @description 任务系统 领奖记录
   * @url http://apisz.thevochat.com:13000/project/11/interface/api/6362
   */
  getTaskPrizeRecords(params: GetTaskPrizeRecordsParams) {
    const url = '/api/web/activities/task/prize/records';
    return request.post<GetTaskPrizeRecordsRes>(url, {
      ...params,
    });
  },
  /**
   * @description 获取指定奖池的信息
      获取指定奖池的信息
      POST /api/web/probability/prizeInfo
      接口ID：70756037
      接口地址：https://www.apifox.cn/link/project/2475043/apis/api-70756037
   */
  getJackpotPrizeInfo(params: GetJackpotPrizeInfoParams) {
    const url = '/api/web/probability/prizeInfo';
    return request.post<GetJackpotPrizeInfoRes>(url, {
      ...params,
    });
  },

  /**
   * @description 获取榜单 包含用户榜 房间榜
      榜单查询
      POST /api/web/activities_config/rank
      接口ID：77360736
      接口地址：https://www.apifox.cn/link/project/2475043/apis/api-77360736
   */
  getRanking(params: GetRankingParams) {
    const url = '/api/web/activities_config/rank';

    return request.post<GetRankingRes>(url, {
      ...params,
    });
  },

  /**
   * @description 阶梯任务信息
      阶梯任务信息
      POST /api/web/act/task/v2/staircase/info
      接口ID：81823361
      接口地址：https://www.apifox.cn/link/project/2475043/apis/api-81823361
   */
  getTaskInfoV2(params: GetRankingParams) {
    const url = '/api/web/act/task/v2/info';

    return request.post<GetTaskInfoV2Res>(url, {
      ...params,
    });
  },
  /**
   * @description 任务二期领奖接口
   */
  onTaskInfoReceiveV2(params: OnTaskInfoReceiveV2Params) {
    const url = '/api/web/act/task/v2/staircase/receive';

    return request.post(url, {
      ...params,
    });
  },

  /**
   * @description 礼包兑换
   */
  getPrizePackage(params: GetPrizePackageParams) {
    const url = '/api/web/act/prizePackage/info';

    return request.post<GetPrizePackageRes>(url, {
      ...params,
    });
  },
  // 根据 ptype 获取虚拟道具信息
  getVirtualProps(params: GetVirtualPropsParams) {
    const url = '/api/web/user/pack/type/info/list';
    return request.post<GetVirtualPropsRes>(url, {
      ...params,
    });
  },
  /**
   * 抽奖系统
   */
  onLottery(params: OnLotteryParams) {
    const url = '/api/web/draw/sys/draw';
    return request.post<OnLotteryRes>(url, {
      ...params,
    });
  },

  /**
   * 抽奖系统轮播信息
   */
  getLotteryCarouselInfo(params: RankBaseParams) {
    const url = '/api/web/act/prizePackage/carousel';
    return request.post<GetLotteryCarouselInfoRes>(url, {
      ...params,
    });
  },
  /**
   * 拉取平台 json 文件默认使用该方法
   * @param url
   * @returns
   */
  getJson<T>(url: string) {
    const { isIOS, isSafari } = getBrowserInfos();
    const isClearCache = (appBridge.isAppEnv() && isIOS) || isSafari;
    const params = isClearCache ? { __t__: Date.now() } : {};
    return request.get<{ data: T }>(url, { params });
  },
};
