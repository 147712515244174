/**
 * index
 *
 * @author fukui
 */
import appBridge from '@falla/app-bridge/src';
import { iConfig } from '@falla/config/src/config';
import { COMMON_EVENT_NAMES, RequestStatus } from '@falla/config/src/constants';
import { commonApiService } from '@falla/request-service/src/commonApiService';
import {
  replaceArhboToYiGo,
  replaceFallaToFolo,
  replaceFallaToYigo,
  replaceYiGoToArhbo,
} from '@falla/utils/src/format-util';
import { getI18nTranslationUrl, getLanguage, langMap } from '@falla/utils/src/i18n-util';
import { setPageLoading } from '@falla/utils/src/page-util';
import { isLocalEnv } from '@falla/utils/src/stark-util';
import { event, store } from '@ice/stark-data';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { PagePluginsOptions } from '../types';

const fallbackLng = 'en';
window.__I18N_INIT__ = RequestStatus.loading;

const addLangReaource = (data: Record<string, any>, lang: string) => {
  const map = data[lang];

  const langs = langMap[lang];
  const isLangMap = langs?.length > 0;
  const isFoloApp = appBridge.isFoloApp();
  const isYigoApp = appBridge.isYigoApp();
  const isArhboApp = appBridge.isArhboApp();

  // eslint-disable-next-line guard-for-in
  for (const key in map) {
    // 替换换行符为 <br>
    // map[key] = map[key].replace(/\n/g, '<br>');
    if (isFoloApp) map[key] = replaceFallaToFolo(map[key]);
    if (isYigoApp) map[key] = replaceArhboToYiGo(replaceFallaToYigo(map[key]));
    if (isArhboApp) map[key] = replaceYiGoToArhbo(replaceFallaToYigo(map[key]));
    if (map[key]) continue;

    // 语言兜底
    if (isLangMap) {
      const k = langs.find((v) => data?.[v]?.[key]);
      map[key] = String(data?.[k]?.[key] || '');
      // .replace(/\n/g, '<br>');
      if (isFoloApp) map[key] = replaceFallaToFolo(map[key]);
      if (isYigoApp) map[key] = replaceArhboToYiGo(replaceFallaToYigo(map[key]));
      if (isArhboApp) map[key] = replaceYiGoToArhbo(replaceFallaToYigo(map[key]));
      continue;
    }
  }
  return map;
};

const fetchRemoteResources = async ({
  lng: language,
  fallbackLng: fallbackLanguage,
  url,
}: {
  lng: string;
  fallbackLng: string;
  url: string;
}) => {
  try {
    window.__I18N_INIT__ = RequestStatus.loading;
    setPageLoading(true);
    const { data } = await commonApiService.getJson(url);
    // 设置所有语言环境的i18n
    Object.keys(data).forEach((langKey) => {
      const map = addLangReaource(data, langKey);
      i18n.addResources(langKey, 'translation', map);
    });
    const lang = data[language] ? language : fallbackLanguage;
    i18n.changeLanguage(lang);
    window.__I18N_INIT__ = RequestStatus.success;
    setPageLoading(false);
    event.emit(COMMON_EVENT_NAMES.i18nInit, RequestStatus.success);
  } catch (e) {
    window.__I18N_INIT__ = RequestStatus.error;
    event.emit(COMMON_EVENT_NAMES.i18nInit, RequestStatus.error);
    setPageLoading(false);
  }
};

const initI18nResources = async ({
  translateId,
  isInMain,
  lng,
}: {
  translateId?: number;
  isInMain: boolean;
  lng?: string;
}) => {
  // 没有翻译文件
  const isNotTranslate = !translateId;
  // 在主框架调试的子应用
  const isInMainOfDebugLocalSub = !isInMain && isLocalEnv();
  // 没有翻译文件 || 在主框架调试的子应用
  if (isNotTranslate || isInMainOfDebugLocalSub) {
    window.__I18N_INIT__ = RequestStatus.success;
    return;
  }
  await fetchRemoteResources({
    lng,
    fallbackLng,
    url: getI18nTranslationUrl(translateId, iConfig.isHostProd, iConfig.isDev),
  });
};

const init = (options: PagePluginsOptions) => {
  if (options.isInMain) store.set('i18n', i18n);

  const lng = getLanguage({
    defaultLang: options?.package?.custom?.defaultLang,
    includesLang: options?.package?.custom?.includesLang,
  });

  document.documentElement.setAttribute('lang', lng);

  event.on(COMMON_EVENT_NAMES.refreshI18n, async () => {
    await initI18nResources({
      lng,
      translateId: options.package?.custom?.translateId,
      isInMain: options.isInMain,
    });
  });

  if (!event.has(COMMON_EVENT_NAMES.updateI18nResource)) {
    event.on(COMMON_EVENT_NAMES.updateI18nResource, async (id: number) => {
      await initI18nResources({ lng, translateId: id, isInMain: true });
    });
  }

  i18n
    .use(initReactI18next) // init i18next
    // .use(Backend)
    .init(
      {
        // 引入资源文件
        resources: {},
        // 选择默认语言，选择内容为上述配置中的key，即en/zh
        fallbackLng,
        lng,
        debug: false,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      },
      async () => {
        await initI18nResources({
          lng,
          translateId: options.package?.custom?.translateId,
          isInMain: options.isInMain,
        });
      },
    );
};

export default {
  init,
  initI18nResources,
  i18n,
};
