/**
 * constants
 *
 * @author fukui
 */
// 沙特的服务器时间差，要多5个小时
export const SATimeDifference = 5 * 60 * 60 * 1000;

export enum ApiErrorCode {
  // 金币不足，引导跳转充值页面流程
  coinNotEnough = 22,
  // 代理金币不足
  agentCoinNotEnough = 101,
  // 统一出错提示，axios 过滤器会做统一的 Toast 拦截提醒
  filterToast = 999,
  // 停服更新
  stopTaking = 1007,
  // 背包数量不足，用于特殊场景的拦截提醒
  bagNotEnough = 4001,
  // token 过期 错误
  tokenErr = 10,
}

/**
 * 展示统一默认信息的错误码
 * 999：统一出错提示，axios 过滤器会做统一的 Toast 拦截提醒
 * 1007：停服更新
 */
export const DEFAULT_ERROR_MSG_CODE = [ApiErrorCode.filterToast, ApiErrorCode.stopTaking];

export enum AvatarDefault {
  roomAvatarLoading = 'https://silkroad-res.resygg.com/avatr/room_loading.png',
  roomAvatarDefault = 'https://silkroad-res.resygg.com/avatr/img_room_default_v5.png',
  userAvatarLoading = 'https://silkroad-res.resygg.com/avatr/user_loading.png',
  userAvatarDefault = 'https://silkroad-res.resygg.com/avatr/img_user_default_v5.png',
}

export enum FoloAvatarDefault {
  roomAvatarLoading = 'https://falla-res1.resygg.com/avatar/folo/room-loading.png',
  roomAvatarDefault = 'https://falla-res1.resygg.com/avatar/folo/room-default.png',
  userAvatarLoading = 'https://falla-res1.resygg.com/avatar/folo/avatar-loading.png',
  userAvatarDefault = 'https://falla-res1.resygg.com/avatar/folo/avatar-default.png',
}

export enum COMMON_EVENT_NAMES {
  i18nInit = 'i18nInit',
  refreshI18n = 'refreshI18n',
  updateI18nResource = 'updateI18nResource',
}

export enum RequestStatus {
  loading,
  success,
  error,
  // init,
}

export const StarkEvents = Object.freeze({});

export const GiftGroupDisplayStyleMapping = Object.freeze({
  1: { label: '默认（全部标准尺寸）', value: 1, color: 'processing' },
  2: { label: '第一行显示一个最大', value: 2, color: 'success' },
  3: { label: '第一行显示两个中号', value: 3, color: 'warning' },
  4: { label: '子奖励分组显示', value: 4, color: 'red' },
});

export const ActivityGiftConfigExpireTypeMapping = [
  { label: '不展示角标', value: 8, color: 'gray' },
  { label: '1 day', value: 1, color: 'processing' },
  { label: '3 days', value: 2, color: 'processing' },
  { label: '7 days', value: 3, color: 'processing' },
  { label: '15 days', value: 4, color: 'processing' },
  { label: '30 days', value: 5, color: 'processing' },
  { label: '1 year', value: 6, color: 'processing' },
  { label: 'forever', value: 7, color: 'processing' },
];
